<template>
  <div class="userinfo-orderList">
    <div class="title">{{ $t('wo-de-ding-dan') }}</div>
    <div class="tabs">
      <el-button
        class="tab"
        :class="tabIndex === i ? 'active' : ''"
        @click="changeTab(item, i)"
        v-for="(item, i) in tabList"
        :key="i"
        >{{ item.name }}</el-button
      >
    </div>
    <div class="order-list">
      <div class="list flex-center" v-for="(item, i) in orderList" :key="i">
        <div class="left">
          <div class="line flex-center-between">
            <div>
              {{ $t('ding-dan-hao') }}
              {{ item.OrderNo }}
            </div>
            <div class="orange">{{ getStatusName(item.Status) }}</div>
          </div>
          <div class="line flex-center-between">
            <div>{{ $t('xin-xi') }}</div>
            <div class="">
              <div>
                {{ item.OrderProduct.length }}
                {{ $t('ge-shang-pin') }}
              </div>
              <div>
                {{ $t('lai-zi') }}
                {{ item.Shop.Name }}
              </div>
            </div>
          </div>
          <div class="line flex-center-between">
            <div>{{ $t('jine') }}</div>
            <div class="price">$ {{ item.TotalAmount }}</div>
          </div>
        </div>
        <div class="space"></div>
        <div class="right flex-wrap">
          <el-button @click="toDetail(item)">{{
            $t('cha-kan-ding-dan')
          }}</el-button>
          <el-button v-if="item.Status == 0" @click="toPay(item)">{{
            $t('li-ji-fu-kuan')
          }}</el-button>
          <el-button
            @click="toCancel(item)"
            v-if="item.Status == 0 || item.Status == 1"
            >{{ $t('qu-xiao-ding-dan') }}</el-button
          >
          <el-button
            @click="toEvalute(item)"
            v-if="item.Status == 3 || item.Status == 4"
            >{{ $t('li-ji-ping-jia') }}</el-button
          >
          <el-button @click="showEvalute(item)" v-if="item.Status == 9">{{
            $t('cha-kan-ping-jia')
          }}</el-button>
        </div>
      </div>
    </div>

    <div class="empty-box flex-column-center" v-if="orderList.length == 0">
      <img :src="emptyUrl" alt="" />
      <span>{{ $t('zan-wu-shu-ju') }}</span>
    </div>

    <div class="flex-center user-pager">
      <el-pagination
        background
        @current-change="currentChange"
        :current-page="page.current"
        :page-size="page.size"
        layout=" prev, pager,  next"
        :total="page.total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="$t('shu-ru-zhi-fu-mi-ma')"
      :visible.sync="showPopup"
      width="400px"
      class="pay-dialog"
    >
      <div class="pay-box">
        <div class="label">{{ $t('qing-shu-ru-nin-de-zhi-fu-mi-ma') }}</div>
        <passwordKeyboard v-model="password"></passwordKeyboard>
        <div class="btn">
          <el-button
            type="primary"
            :disabled="password.length != 6"
            @click="doPay"
            >{{ $t('que-ren') }}</el-button
          >
        </div>
      </div>
    </el-dialog>

    <el-dialog
      :title="$t('ding-dan-ping-jia')"
      :visible.sync="showEvalutePopup"
      width="500px"
    >
      <div class="evalute-box">
        <el-form
          :model="evaluteForm"
          ref="evaluteForm"
          label-width="100px"
          :rules="formRule"
        >
          <el-form-item
            prop="ProductScore"
            required
            :label="$t('shang-pin-ping-fen')"
          >
            <div class="flex-center-start" style="height: 36px">
              <el-rate v-model="evaluteForm.ProductScore"></el-rate>
            </div>
          </el-form-item>
          <el-form-item
            prop="ServiceScore"
            required
            :label="$t('fu-wu-ping-fen')"
          >
            <div class="flex-center-start" style="height: 36px">
              <el-rate v-model="evaluteForm.ServiceScore"></el-rate>
            </div>
          </el-form-item>
          <el-form-item
            prop="LogisticsScore"
            required
            :label="$t('wu-liu-ping-fen')"
          >
            <div class="flex-center-start" style="height: 36px">
              <el-rate v-model="evaluteForm.LogisticsScore"></el-rate>
            </div>
          </el-form-item>
          <el-form-item :label="$t('ping-jia-tu-pian')" prop="Logo">
            <el-upload
              style="width: 100px; height: 100px"
              :show-file-list="false"
              :action="uploadUrl"
              :headers="headers"
              name="file"
              :on-success="uploadSuccess"
              :before-upload="beforeUpload"
            >
              <img
                v-if="evaluteForm.Image"
                :src="getBaseUrl(evaluteForm.Image)"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-camera-solid"
                style="font-size: 30px; color: #aaa"
              ></i>
            </el-upload>
          </el-form-item>
          <el-form-item prop="Content" required :label="$t('ping-jia-0')">
            <el-input
              type="textarea"
              v-model="evaluteForm.Content"
              :placeholder="$t('qing-shu-ru-ping-jia-nei-rong-1')"
            ></el-input>
          </el-form-item>

          <el-form-item>
            <el-button
              class="submit-btn"
              type="primary"
              @click="submitEvalute"
              >{{ $t('ti-jiao') }}</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import passwordKeyboard from '@/components/password'
import { getOrderList, cancelOrder, orderPay } from '@/api/user'
import { addEvalute, addGoodsEvalute } from '@/api/shop'
import { getToken } from '@/utils/auth'
import { getBaseUrl } from '@/utils/common'
export default {
  components: {
    passwordKeyboard
  },
  data() {
    return {
      emptyUrl: require('@/assets/imgs/emtpy.svg'),
      tabIndex: 0,
      orderList: [],
      evaluteForm: {},
      tabList: [
        {
          name: this.$t('quan-bu'),
          status: ''
        },
        {
          name: this.$t('dai-zhi-fu'),
          status: '0'
        },
        {
          name: this.$t('dai-fa-huo'),
          status: '1'
        },
        {
          name: this.$t('dai-shou-huo'),
          status: '2'
        },
        {
          name: this.$t('dai-ping-jia'),
          status: '3'
        },
        {
          name: this.$t('tui-kuan-shou-hou'),
          status: '7,8'
        }
      ],
      page: {
        size: 10,
        current: 1,
        total: 1
      },
      form: {
        status: ''
      },
      showPopup: false,
      showEvalutePopup: false,
      showEvaluteDetailPopup: false,
      password: '',
      orderId: '',
      productID: '',
      formRule: {
        ProductScore: [
          {
            message: this.$t('qing-xuan-ze-shang-pin-ping-fen'),
            required: true
          }
        ],
        LogisticsScore: [
          { message: this.$t('qing-xuan-ze-wu-liu-ping-fen'), required: true }
        ],
        ServiceScore: [
          { message: this.$t('qing-xuan-ze-fu-wu-ping-fen'), required: true }
        ],
        Content: [
          {
            message: this.$t('qing-shu-ru-ping-jia-nei-rong-0'),
            required: true
          }
        ]
      },
      headers: {}
    }
  },
  computed: {
    imgType() {
      return this.$store.state.imgType
    },
    uploadUrl() {
      return this.$store.state.uploadUrl
    }
  },
  mounted() {
    this.init()
    this.headers = {
      Token: getToken()
    }
  },
  methods: {
    getBaseUrl,
    init() {
      getOrderList({
        current: this.page.current,
        pageSize: this.page.size,
        Status: this.form.status ? parseFloat(this.form.status) : null
      }).then((res) => {
        this.orderList = res.data.Items
        this.page.total = res.data.Pagination.totalRecords
      })
    },
    changeTab(data, i) {
      this.tabIndex = i
      this.form.status = data.status
      this.page.current = 1
      this.init()
    },
    currentChange(page) {
      this.page.current = page
      this.init()
    },
    toDetail(data) {
      sessionStorage.setItem('orderDetailData', JSON.stringify(data))
      this.$router.push({
        name: 'userInfoOrderDetail'
      })
    },
    getStatusName(status) {
      let name = ''
      switch (status + '') {
        case '0':
          name = this.$t('dai-fu-kuan')
          break
        case '1':
          name = this.$t('dai-fa-huo')
          break
        case '2':
          name = this.$t('dai-shou-huo')
          break
        case '3':
          name = this.$t('dai-ping-jia')
          break
        case '4':
          name = this.$t('yi-wan-cheng')
          break
        case '5':
          name = this.$t('yi-qu-xiao')
          break
        case '6':
          name = this.$t('yi-guan-bi')
          break
        case '7':
          name = this.$t('tui-huo-zhong')
          break
        case '8':
          name = this.$t('tui-kuan-cheng-gong')
          break
        case '9':
          name = this.$t('yi-ping-jia')
          break
      }
      return name
    },
    toCancel(data) {
      this.$confirm(
        this.$t('que-ren-qu-xiao-ding-dan'),
        this.$t('que-ren')
      ).then((res) => {
        cancelOrder({
          ID: data.ID
        }).then((res) => {
          if (res.code == 0) {
            this.$message.success(this.$t('qu-xiao-cheng-gong'))
            this.init()
          } else {
            this.$message.warning(res.msg)
          }
        })
      })
    },
    toPay(data) {
      this.orderId = data.ID
      this.showPopup = true
    },
    doPay() {
      orderPay({
        OrderID: this.orderId,
        Password: parseInt(this.password)
      }).then((res) => {
        if (res.code == 0) {
          this.$message.success(this.$t('zhi-fu-cheng-gong'))
          this.showPopup = false
          this.init()
        } else {
          this.$message.warning(res.msg)
        }
      })
    },
    toEvalute(data) {
      this.showEvalutePopup = true
      this.orderId = data.ID
      this.productID = data.OrderProduct[0].BusinessProductId
    },
    submitEvalute() {
      this.$refs.evaluteForm.validate((vali) => {
        if (vali) {
          addEvalute({
            OrderId: this.orderId,
            ProductScore: this.evaluteForm.ProductScore,
            ServiceScore: this.evaluteForm.ServiceScore,
            LogisticsScore: this.evaluteForm.LogisticsScore,
            Content: this.evaluteForm.Content,
            Image: this.evaluteForm.Image || null
          }).then((res) => {
            if (res.code == 0) {
              this.$message.success(this.$t('ping-jia-cheng-gong'))
              this.showEvalutePopup = false
              this.$refs.evaluteForm.resetFields()
            } else {
              this.$message.warning(res.msg)
            }
          })

          // 商品评论
          addGoodsEvalute({
            ProductID: this.productID,
            Score: this.evaluteForm.ProductScore,
            Content: this.evaluteForm.Content
          })
        }
      })
    },
    uploadSuccess(res) {
      this.evaluteForm.Image = res.data.FileName
      this.$forceUpdate()
    },
    beforeUpload(file) {
      let name = file.name
      let type = name.substring(name.indexOf('.') + 1)
      if (this.imgType.indexOf(type) === -1) {
        this.$message.error(this.$t('zhi-neng-shang-chuan-tu-pian'))
        return false
      }
      return true
    },
    showEvalute(data) {
      this.showEvaluteDetailPopup = true
    }
  }
}
</script>